// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-default-js": () => import("../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-diensten-js": () => import("../src/templates/diensten.js" /* webpackChunkName: "component---src-templates-diensten-js" */),
  "component---src-templates-branches-js": () => import("../src/templates/branches.js" /* webpackChunkName: "component---src-templates-branches-js" */),
  "component---src-templates-nieuws-js": () => import("../src/templates/nieuws.js" /* webpackChunkName: "component---src-templates-nieuws-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanvraagformulier-js": () => import("../src/pages/aanvraagformulier.js" /* webpackChunkName: "component---src-pages-aanvraagformulier-js" */),
  "component---src-pages-branches-js": () => import("../src/pages/branches.js" /* webpackChunkName: "component---src-pages-branches-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diensten-js": () => import("../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nieuws-js": () => import("../src/pages/nieuws.js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-referenties-js": () => import("../src/pages/referenties.js" /* webpackChunkName: "component---src-pages-referenties-js" */),
  "component---src-pages-sitemap-js": () => import("../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-vacatures-js": () => import("../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */)
}

